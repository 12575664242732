<style lang="scss">
</style>
<!--eslint-disable-->
<template>
  <div class="Kiosk-Tags-page">
    <b-card class="" title="Kiosk Tags">
      <b-card-text>
        View, add, update and remove tags. Tags can then be attached to different sites to help categorize and group them together.
        This feature can help organize and streamline site management, making it easier to find and sort relevant content for users.
      </b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="row p-2 justify-content-start">

        <!-- Search -->
        <b-col class="col-md-6 col-12 mt-1">
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              @keyup.enter="getMoreTags(1)"
              class="d-inline-block" placeholder="Search by Name..."
            />
            <b-input-group-append>
              <b-button @click="getMoreTags(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col class="col-md-6 col-12 mt-1">
          <b-input-group class="mb-1">
            <b-form-input
              class="d-inline-block" @keyup.enter="createTag" placeholder="Add Tag..." v-model="tag.name"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="createTag" :disabled="tag.name === ''">Add</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </div>

      <section>
        <b-table
          class="data-table"
          hover
          :items="tags"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" @click="showDeleteModal(data.item, 'modal-delete-kiosk-tag')">
                Delete
              </b-dropdown-item-button>
            </b-dropdown>
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreTags" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-delete-kiosk-tag" title="Delete Tag" hide-footer>
      <delete-modal
        v-if="selectedTag"
        @close="closeModals()" @delete="deleteTag"
        :subtitle="tag.name"
        title="Are you sure you want to delete this tag?"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeleteModal from '@/components/modals/DeleteModal.vue';
import HelperService from '@/services/HelperService'
import KioskTagService from '@/services/KioskTagService';

export default {
  name: 'KioskTags',
  components: {
    DeleteModal,
  },
  props: {},
  data() {
    return {
      tag: {
        name: '',
      },
      selectedTag: null,
      headers: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      tags: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
    };
  },
  mounted() {
    this.getTags();
  },
  methods: {
    refreshTags() {
      this.closeModals();
      this.getTags();
    },
    getMoreTags(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getTags();
    },
    async getTags() {
      try {
        const res = await KioskTagService.listTags(this.filters)
        this.tags = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        HelperService.showNotfyErr(this.$toast, err, 'Could not get kiosk tags, please refresh and try again')
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };

      this.getMoreTags(1);
    },
    closeModals() {
      this.$bvModal.hide('modal-delete-kiosk-tag');
    },
    showDeleteModal(tag, modalName) {
      this.selectedTag = tag;
      this.$bvModal.show(modalName);
    },
    async deleteTag() {
      try {
        await KioskTagService.deleteTag(this.selectedTag.id)
        this.$toast.success(`Deleted ${this.selectedTag.name} successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.refreshTags();
      } catch (err) {
        HelperService.showNotfyErr(this.$toast, err, 'Could not delete entry, please refresh and try again')
      }
    },
    async createTag() {
      try {
        await KioskTagService.createTag(this.tag)

        this.$toast.success(`Added ${this.tag.name}`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.tag.name = ''
        this.refreshTags();
      } catch (err) {
        HelperService.showNotfyErr(this.$toast, err, 'Could not create entry, please refresh and try again')
      }
    },
  },
};
</script>
