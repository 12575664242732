import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/admin/kiosk-tags';

export default {
  listTags(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getTag(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
  createTag(tag) {
    return axios.post(`${baseUrl}`, tag)
  },
  updateTag(id, tag) {
    return axios.put(`${baseUrl}/${id}`, tag)
  },
  deleteTag(id) {
    return axios.delete(`${baseUrl}/${id}`)
  },
}
